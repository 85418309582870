import Box from 'fe-design-base/atoms/Box';
import NumberInput from 'fe-design-base/atoms/NumberInput';
import Text from 'fe-design-base/atoms/Text';
import SelectField from 'fe-design-base/molecules/SelectField';

import { toI18n } from 'util/i18n';

const I18N_PATH = 'new_team_drawer.add_team_form.labels';

import { useCallback, useEffect, useState } from 'react';
import { InputChangeEvent } from 'fe-design-base/baseTypes';
import { useFormikContext } from 'formik';

import { TimeOffPolicyOptions } from './types';

const PolicySelection = ({
  timeOffPolicyOptions,
  policyType,
  initialPolicyId,
  initialBalance,
}: {
  timeOffPolicyOptions: TimeOffPolicyOptions[];
  policyType: string;
  initialPolicyId?: string | number;
  initialBalance?: number;
}) => {
  const { setFieldValue } = useFormikContext();

  const [timeOffPolicy, setTimeOffPolicy] = useState(
    initialPolicyId ? String(initialPolicyId) : 'none'
  );
  const [timeOffPolicyBalance, setTimeOffPolicyBalance] = useState(
    initialBalance || 0
  );

  // Set initial field values if provided
  useEffect(() => {
    if (initialPolicyId) {
      setFieldValue(`time_off_policies.${policyType}.id`, initialPolicyId);
    }
    if (initialBalance !== undefined) {
      setFieldValue(`time_off_policies.${policyType}.balance`, initialBalance);
    }
  }, [initialPolicyId, initialBalance, policyType, setFieldValue]);

  // Update state if initialPolicyId or initialBalance changes
  useEffect(() => {
    if (initialPolicyId !== undefined) {
      setTimeOffPolicy(String(initialPolicyId));
    }
  }, [initialPolicyId]);

  useEffect(() => {
    if (initialBalance !== undefined) {
      setTimeOffPolicyBalance(initialBalance);
    }
  }, [initialBalance]);

  const handleTimeOffPolicyChange = useCallback(
    (e: InputChangeEvent) => {
      if (e.target.value !== 'none' && !!e.target.value) {
        setTimeOffPolicy(e.target.value);
        setFieldValue(`time_off_policies.${policyType}.id`, e.target.value);
      } else {
        setTimeOffPolicy('none');
        setTimeOffPolicyBalance(0);
        setFieldValue(`time_off_policies.${policyType}.id`, null);
        setFieldValue(`time_off_policies.${policyType}.balance`, null);
      }
    },
    [policyType, setFieldValue, setTimeOffPolicy, setTimeOffPolicyBalance]
  );
  const handleTimeOffPolicyBalanceChange = useCallback(
    (e: InputChangeEvent) => {
      setTimeOffPolicyBalance(e.target.value);
      setFieldValue(
        `time_off_policies.${policyType}.balance`,
        Number(e.target.value)
      );
    },
    [policyType, setFieldValue, setTimeOffPolicyBalance]
  );

  return (
    <Box mt={24} row gap={24}>
      <Box w={288}>
        <SelectField
          name={`time_off_policies.${policyType}.id`}
          value={timeOffPolicy}
          label={toI18n(`${I18N_PATH}.${policyType}`)}
          onChange={handleTimeOffPolicyChange}
          options={timeOffPolicyOptions}
          clearIcon={timeOffPolicy !== 'none'}
        />
      </Box>
      <Box>
        <Box column gap={8}>
          <Text
            variant="bodySmBold"
            color="mono700"
            i18n={`${I18N_PATH}.balance`}
          />
          <Box w="100px" row gap={4}>
            <NumberInput
              leftAlign
              allowNegative={false}
              disabled={timeOffPolicy === 'none'}
              name={`time_off_policies.${policyType}.balance`}
              value={timeOffPolicyBalance}
              placeholder="0"
              onChange={handleTimeOffPolicyBalanceChange}
            />
            <Box alignItemsEnd ml={2}>
              <Text variant="body" color="mono700">
                hrs
              </Text>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default PolicySelection;
